import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { A11y, Autoplay, Navigation, Pagination } from "swiper";
import { Swiper as SwiperComponent, SwiperSlide } from "swiper/react";

export const Swiper = ({
  items,
  breakpoints = {},
  renderSlide,
  hidePagination = false,
  hideNavigation = false,
  autoPlay = false,
  hideNavigationButtons = false,
  children,
  spaceBetween,

  ...props
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const itemsCount = items?.length || 0;
  const [numSlides, setNumSlides] = useState(items?.length || 0);
  const [swiperInstance, setSwiperInstance] = useState(null);

  useEffect(() => {
    if (swiperInstance) {
      swiperInstance.update();
    }
  }, [itemsCount, swiperInstance]);

  useEffect(() => {
    setNumSlides(items?.length);
  }, [items?.length]);

  const modules = useMemo(() => {
    const mods = [A11y];

    if (!hidePagination) {
      mods.push(Pagination);
    }

    if (!hideNavigation) {
      mods.push(Navigation);
    }

    if (autoPlay) {
      mods.push(Autoplay);
    }

    return mods;
  }, [hidePagination, hideNavigation, autoPlay]);

  return (
    <>
      <SwiperComponent
        dir={language === "ar" ? "rtl" : "ltr"}
        modules={modules}
        spaceBetween={50}
        slidesPerView="auto"
        navigation={!hideNavigationButtons}
        //  && numSlides >= itemsCount
        breakpoints={{
          320: {
            slidesPerView: "auto",
            spaceBetween: spaceBetween || 20,
          },
          480: {
            slidesPerView: "auto",
            spaceBetween: spaceBetween || 30,
          },
          640: {
            slidesPerView: "auto",
            spaceBetween: spaceBetween || 20,
          },
          1024: {
            slidesPerView: "auto",
            spaceBetween: spaceBetween || 20,
          },
          ...breakpoints,
        }}
        onSwiper={setSwiperInstance}
        freeMode
        {...props}
      >
        {children
          ? children
          : items?.map((item, index) => (
              <SwiperSlide key={`slide-${index}`} className="w-auto">
                {renderSlide?.(item, index)}
              </SwiperSlide>
            ))}
      </SwiperComponent>
    </>
  );
};
